import React, {useEffect, useRef, useState} from "react";
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry";
import Modal from "react-modal";
import 'aos/dist/aos.css'; // Import AOS styles
import AOS from 'aos';
import OwlCarousel from "react-owl-carousel";
import styled from "styled-components";
import DatePicker from "react-datepicker";
import emailjs from "emailjs-com";
import {Button} from "react-bootstrap";
import {Event} from "./Event";
import {EventHome} from "./EventHome";



// Initialize AOS
AOS.init();

export const Home = () => {

    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedTime, setSelectedTime] = useState(null);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [noOfPeople, setNoOfPeople] = useState(null);
    const [specialRequest, setSpecialRequest] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');

    const handleDateChange = (date) => setSelectedDate(date);
    const handleTimeChange = (time) => setSelectedTime(time);

    const handleBooking = (method) => {
        const bookingDetails = `Name: ${name}\nDate: ${selectedDate?.toLocaleDateString()}\nTime: ${selectedTime?.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}\nPeople: ${noOfPeople}\nSpecial Request: ${specialRequest}`;
        const receiverPhoneNumber = '+94774163553'; // Ensure this is correct

        if (method === 'whatsapp') {
            const whatsappUrl = `https://wa.me/${receiverPhoneNumber}?text=${encodeURIComponent(bookingDetails)}`;
            window.open(whatsappUrl, '_blank');
        } else if (method === 'email') {
            const emailData = {
                name,
                email,
                date: selectedDate?.toLocaleDateString(),
                time: selectedTime?.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
                noOfPeople,
                specialRequest,
                subject,
                message,
            };

            emailjs.send(
                'service_p8unrnn',        // Replace with your EmailJS service ID
                'template_21fewum',       // Replace with your EmailJS template ID
                emailData,
                'R7u8CJEZzlSXzwXH7'       // Replace with your EmailJS user ID
            )
                .then((response) => {
                    console.log('SUCCESS!', response.status, response.text);
                    alert('Email sent successfully!');
                })
                .catch((err) => {
                    console.error('FAILED...', err);
                    alert('Failed to send email. Please try again later.');
                });
        }
    };

    const StyledDatePicker = styled(DatePicker)`
    width: 100%;
    height: 60px;
    border: none !important;
    box-shadow: none !important;
    background-color: white;
`;


    /*Event*/


    /*comment*/
    const testimonials = [
        {
            text: "Dolor et eos labore, stet justo sed est sed. Diam sed sed dolor stet amet eirmod eos labore diam",
            img: "/img/testimonial-1.jpg",
            name: "Client Name 1",
            profession: "Profession 1",
        },
        {
            text: "Dolor et eos labore, stet justo sed est sed. Diam sed sed dolor stet amet eirmod eos labore diam",
            img: "/img/testimonial-2.jpg",
            name: "Client Name 2",
            profession: "Profession 2",
        },
        {
            text: "Dolor et eos labore, stet justo sed est sed. Diam sed sed dolor stet amet eirmod eos labore diam",
            img: "/img/testimonial-3.jpg",
            name: "Client Name 3",
            profession: "Profession 3",
        },
        {
            text: "Dolor et eos labore, stet justo sed est sed. Diam sed sed dolor stet amet eirmod eos labore diam",
            img: "/img/testimonial-4.jpg",
            name: "Client Name 4",
            profession: "Profession 4",
        },
    ];

    const options = {
        loop: true,
        margin: 10,
        nav: false,
        dots: false,
        autoplay: true,
        autoplayTimeout: 3000, // 3 seconds between each slide
        autoplayHoverPause: true, // Pause on hover
        responsive: {
            0: {
                items: 1,
            },
            600: {
                items: 2,
            },
            1000: {
                items: 3,
            },
        },
    };

    /*Gallery*/
    const allImages = [
        { image: 'img/event1.jpg' },
        { image: 'img/event3.jpg' },
        { image: 'img/event2.jpg' },
        { image: 'img/event3.jpg' },
        { image: 'img/event2.jpg' },
        { image: 'img/event1.jpg' },
        { image: 'img/event3.jpg' },
        { image: 'img/event2.jpg' },
        { image: 'img/event1.jpg' },
        { image: 'img/event2.jpg' },
        { image: 'img/event3.jpg' }
    ];


        const [isOpen, setIsOpen] = useState(false);
        const [currentImage, setCurrentImage] = useState('');
        const [showMore, setShowMore] = useState(false); // State to toggle between showing more or less

        const openModal = (img) => {
            setCurrentImage(img);
            setIsOpen(true);
        };

        const closeModal = () => {
            setIsOpen(false);
            setCurrentImage('');
        };

        const toggleShowMore = () => {
            setShowMore(!showMore);
        };

        // Determine the images to display based on the showMore state
        const imagesToShow = showMore ? allImages : allImages.slice(0, 6);

        /*about*/
    const [isModalOpen, setIsModalOpen] = useState(false);

    const toggleModal = () => {
        setIsModalOpen(!isModalOpen);
    };

    return(

        <>
            <div className="container-xxl py-5 bg-dark hero-header mb-5">
                <div className="container my-5 py-5">
                    <div className="row align-items-center g-5">
                        <div className="col-lg-6 text-center text-lg-start">
                            <h1 className="display-3 text-white animated slideInLeft">Savor Our<br/>Exquisite Cuisine</h1>
                            <p className="text-white animated slideInLeft mb-4 pb-2">Indulge in a culinary journey where every dish is a masterpiece. Fresh ingredients, rich flavors, and expert preparation come together to create a dining experience you'll never forget.</p>
                            <a href="book" className="btn btn-primary py-sm-3 px-sm-5 me-3 animated slideInLeft">Book A Table</a>
                        </div>

                        <div className="col-lg-6 text-center text-lg-end overflow-hidden">
                            <img className="img-fluid" src="img/hero.png" alt=""/>
                        </div>
                    </div>
                </div>
            </div>

             {/*Service Start*/}

            <div id="service" className="container-xxl py-5">
                <div className="container">
                    <div className="row g-4">
                        <div className="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.1s">
                            <div className="service-item rounded pt-5 d-flex flex-column h-100">
                                <div className="icon-wrapper">
                                    <i className="fa fa-user-tie text-black-90"></i>
                                </div>
                                <div className="p-4 text-center flex-grow-1">
                                    <h5>Master Chefs</h5>
                                    <p>Our expert chefs craft exquisite dishes with passion, blending flavors to create an unforgettable dining experience.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.3s">
                            <div className="service-item rounded pt-5 d-flex flex-column h-100">
                                <div className="icon-wrapper">
                                    <i className="fa fa-utensils text-black-90"></i>
                                </div>
                                <div className="p-4 text-center flex-grow-1">
                                    <h5>Quality Food</h5>
                                    <p>Our commitment to quality ensures every dish is made with the freshest ingredients, delivering flavors that delight your senses.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.5s">
                            <div className="service-item rounded pt-5 d-flex flex-column h-100">
                                <div className="icon-wrapper">
                                    <i className="fa fa-cart-plus text-black-90"></i>
                                </div>
                                <div className="p-4 text-center flex-grow-1">
                                    <h5>Online Order</h5>
                                    <p>Conveniently place your order online and enjoy a seamless dining experience. Fresh ingredients, quick delivery, and delicious meals are just a click away.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.7s">
                            <div className="service-item rounded pt-5 d-flex flex-column h-100">
                                <div className="icon-wrapper">
                                    <i className="fa fa-headset text-black-90"></i>
                                </div>
                                <div className="p-4 text-center flex-grow-1">
                                    <h5>24/7 Service</h5>
                                    <p>We are here for you around the clock, providing exceptional service whenever you need it. Your satisfaction is our priority, day or night.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
           {/* Service End*/}


            {/*About Start*/}
            <div className="container-xxl py-5" id="about">
                <div className="container">
                    <div className="row g-5 align-items-center">
                        <div className="col-lg-6">
                            <div className="row g-3">
                                <div className="col-6 text-start" data-aos="zoom-in" data-aos-delay="100">
                                    <img className="img-fluid rounded w-100" src="img/about-1.jpg" alt="About Image 1" />
                                </div>
                                <div className="col-6 text-start" data-aos="zoom-in" data-aos-delay="300">
                                    <img className="img-fluid rounded w-75" src="img/about-2.jpg" alt="About Image 2" style={{ marginTop: '25%' }} />
                                </div>
                                <div className="col-6 text-end" data-aos="zoom-in" data-aos-delay="500">
                                    <img className="img-fluid rounded w-75" src="img/about-3.jpg" alt="About Image 3" />
                                </div>
                                <div className="col-6 text-end" data-aos="zoom-in" data-aos-delay="700">
                                    <img className="img-fluid rounded w-100" src="img/about-4.jpg" alt="About Image 4" />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <h5 className="section-title ff-secondary text-center text-primary fw-normal" data-aos="fade-up">About Us</h5>
                            <h1 className="mb-4" data-aos="fade-up" data-aos-delay="200">
                                Welcome to <i className="fa fa-utensils text-primary me-2"></i>Hug Inn
                            </h1>
                            <p className="mb-4" data-aos="fade-up" data-aos-delay="300">
                                At Hug Inn, we blend tradition and innovation to bring you a dining experience like no other. Every dish is prepared with the finest ingredients, ensuring a symphony of flavors in every bite.
                            </p>
                            <p className="mb-4" data-aos="fade-up" data-aos-delay="400">
                                Whether you’re joining us for a casual meal or a special occasion, our welcoming atmosphere and exceptional service will make your visit memorable. Discover the passion behind our cuisine, where every meal tells a story.
                            </p>
                            <div className="row g-4 mb-4">
                                <div className="col-sm-6" data-aos="fade-up" data-aos-delay="500">
                                    <div className="d-flex align-items-center border-start border-5 border-primary px-3">
                                        <h1 className="flex-shrink-0 display-5 text-primary mb-0" data-toggle="counter-up">15</h1>
                                        <div className="ps-4">
                                            <p className="mb-0">Years of</p>
                                            <h6 className="text-uppercase mb-0">Experience</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6" data-aos="fade-up" data-aos-delay="600">
                                    <div className="d-flex align-items-center border-start border-5 border-primary px-3">
                                        <h1 className="flex-shrink-0 display-5 text-primary mb-0" data-toggle="counter-up">50</h1>
                                        <div className="ps-4">
                                            <p className="mb-0">Popular</p>
                                            <h6 className="text-uppercase mb-0">Master Chefs</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <button className="btn btn-primary py-3 px-5 mt-2" onClick={toggleModal} data-aos="fade-up" data-aos-delay="700">
                                Read More
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {/* Modal */}
            {isModalOpen && (
                <div className="modal show" style={{ display: 'block' }} tabIndex="-1">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">More About Us</h5>
                                <button type="button" className="btn-close" onClick={toggleModal}></button>
                            </div>
                            <div className="modal-body">
                                <p>
                                    At Hug Inn, our journey began over 15 years ago with a passion for culinary excellence. Our team of 50 master chefs brings diverse flavors and innovative techniques to the table, creating dishes that are as memorable as they are delicious. Whether you are a frequent guest or a first-time visitor, we are committed to making your dining experience exceptional.
                                </p>
                                <p>
                                    Our restaurant is more than just a place to eat—it's a destination where you can relax, enjoy good company, and savor a meal prepared with love and dedication. Thank you for choosing Hug Inn. We look forward to serving you!
                                </p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" onClick={toggleModal}>
                                    Close
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {/* Backdrop */}
            {isModalOpen && <div className="modal-backdrop fade show"></div>}
            {/*About End*/}


            {/*Menu Start*/}
            <div id="menu" className="container-xxl py-5" >
                <div className="container">
                    <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
                        <h5 className="section-title ff-secondary text-center text-primary fw-normal">Food Menu</h5>
                        <h1 className="mb-5">Most Popular Items</h1>
                    </div>
                    <div className="tab-class text-center wow fadeInUp" data-wow-delay="0.1s">
                        <ul className="nav nav-pills d-inline-flex justify-content-center border-bottom mb-5">
                            <li className="nav-item">
                                <a className="d-flex align-items-center text-start mx-3 ms-0 pb-3 active" data-bs-toggle="pill" href="#tab-1">
                                    <i className="fa fa-coffee fa-2x text-primary"></i>
                                    <div className="ps-3">
                                        <small className="text-body">Popular</small>
                                        <h6 className="mt-n1 mb-0">Breakfast</h6>
                                    </div>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="d-flex align-items-center text-start mx-3 pb-3" data-bs-toggle="pill" href="#tab-2">
                                    <i className="fa fa-hamburger fa-2x text-primary"></i>
                                    <div className="ps-3">
                                        <small className="text-body">Special</small>
                                        <h6 className="mt-n1 mb-0">Lunch</h6>
                                    </div>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="d-flex align-items-center text-start mx-3 me-0 pb-3" data-bs-toggle="pill" href="#tab-3">
                                    <i className="fa fa-utensils fa-2x text-primary"></i>
                                    <div className="ps-3">
                                        <small className="text-body">Lovely</small>
                                        <h6 className="mt-n1 mb-0">Dinner</h6>
                                    </div>
                                </a>
                            </li>
                        </ul>
                        <div className="tab-content">
                            {/*-----Tab 1------------------*/}
                            <div id="tab-1" className="tab-pane fade show p-0 active">
                                <div className="row g-4">
                                    <div className="col-lg-6">
                                        <div className="d-flex align-items-center">
                                            <img className="flex-shrink-0 img-fluid rounded" src="img/menu-6.jpg" alt="" style={{ width: '80px' }} />
                                            <div className="w-100 d-flex flex-column text-start ps-4">
                                                <h5 className="d-flex justify-content-between border-bottom pb-2">
                                                    <span>Chicken Burger</span>
                                                    <span className="text-primary">$115</span>
                                                </h5>
                                                <small className="fst-italic">Ipsum ipsum clita erat amet dolor justo diam</small>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="d-flex align-items-center">
                                            <img className="flex-shrink-0 img-fluid rounded" src="img/menu-8.jpg" alt="" style={{ width: '80px' }} />
                                            <div className="w-100 d-flex flex-column text-start ps-4">
                                                <h5 className="d-flex justify-content-between border-bottom pb-2">
                                                    <span>Chicken Burger</span>
                                                    <span className="text-primary">$115</span>
                                                </h5>
                                                <small className="fst-italic">Ipsum ipsum clita erat amet dolor justo diam</small>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="d-flex align-items-center">
                                            <img className="flex-shrink-0 img-fluid rounded" src="img/menu-4.jpg" alt="" style={{ width: '80px' }} />
                                            <div className="w-100 d-flex flex-column text-start ps-4">
                                                <h5 className="d-flex justify-content-between border-bottom pb-2">
                                                    <span>Chicken Burger</span>
                                                    <span className="text-primary">$115</span>
                                                </h5>
                                                <small className="fst-italic">Ipsum ipsum clita erat amet dolor justo diam</small>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Additional menu items */}
                                </div>
                            </div>
                            {/*-------------Tab 2-----------------*/}
                            <div id="tab-2" className="tab-pane fade show p-0">
                                <div className="row g-4">
                                    <div className="col-lg-6">
                                        <div className="d-flex align-items-center">
                                            <img className="flex-shrink-0 img-fluid rounded" src="img/menu-1.jpg" alt="" style={{ width: '80px' }} />
                                            <div className="w-100 d-flex flex-column text-start ps-4">
                                                <h5 className="d-flex justify-content-between border-bottom pb-2">
                                                    <span>Chicken Burger</span>
                                                    <span className="text-primary">$115</span>
                                                </h5>
                                                <small className="fst-italic">Ipsum ipsum clita erat amet dolor justo diam</small>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="d-flex align-items-center">
                                            <img className="flex-shrink-0 img-fluid rounded" src="img/menu-8.jpg" alt="" style={{ width: '80px' }} />
                                            <div className="w-100 d-flex flex-column text-start ps-4">
                                                <h5 className="d-flex justify-content-between border-bottom pb-2">
                                                    <span>Chicken Burger</span>
                                                    <span className="text-primary">$115</span>
                                                </h5>
                                                <small className="fst-italic">Ipsum ipsum clita erat amet dolor justo diam</small>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="d-flex align-items-center">
                                            <img className="flex-shrink-0 img-fluid rounded" src="img/menu-7.jpg" alt="" style={{ width: '80px' }} />
                                            <div className="w-100 d-flex flex-column text-start ps-4">
                                                <h5 className="d-flex justify-content-between border-bottom pb-2">
                                                    <span>Chicken Burger</span>
                                                    <span className="text-primary">$115</span>
                                                </h5>
                                                <small className="fst-italic">Ipsum ipsum clita erat amet dolor justo diam</small>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="d-flex align-items-center">
                                            <img className="flex-shrink-0 img-fluid rounded" src="img/menu-7.jpg" alt="" style={{ width: '80px' }} />
                                            <div className="w-100 d-flex flex-column text-start ps-4">
                                                <h5 className="d-flex justify-content-between border-bottom pb-2">
                                                    <span>Chicken Burger</span>
                                                    <span className="text-primary">$115</span>
                                                </h5>
                                                <small className="fst-italic">Ipsum ipsum clita erat amet dolor justo diam</small>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Additional menu items */}
                                </div>
                            </div>
                            {/*--------------Tab 3-----------*/}
                            <div id="tab-3" className="tab-pane fade show p-0">
                                <div className="row g-4">
                                    <div className="col-lg-6">
                                        <div className="d-flex align-items-center">
                                            <img className="flex-shrink-0 img-fluid rounded" src="img/menu-2.jpg" alt="" style={{ width: '80px' }} />
                                            <div className="w-100 d-flex flex-column text-start ps-4">
                                                <h5 className="d-flex justify-content-between border-bottom pb-2">
                                                    <span>Chicken Burger</span>
                                                    <span className="text-primary">$115</span>
                                                </h5>
                                                <small className="fst-italic">Ipsum ipsum clita erat amet dolor justo diam</small>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Additional menu items */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*Menu End*/}


           {/* Reservation Start*/}
            <div id="book" className="container-xxl py-5 px-0">
                <div className="row g-0">
                    <div className="col-md-6">
                        <div className="video">
                            <button type="button" className="btn-play" data-bs-toggle="modal" data-src="https://www.youtube.com/embed/0GdQY5ese0s?si=0agflFjzhdhI9gbT" data-bs-target="#videoModal">
                                <span></span>
                            </button>
                        </div>
                    </div>
                    <div className="col-md-6 bg-dark d-flex align-items-center">
                        <div className="p-5 wow fadeInUp" data-wow-delay="0.2s">
                            <h5 className="section-title ff-secondary text-start text-primary fw-normal">Reservation</h5>
                            <h1 className="text-white mb-4">Book A Table Online</h1>
                            <form onSubmit={(e) => e.preventDefault()}>
                                <div className="row g-3">
                                    <div className="col-md-6">
                                        <div className="form-floating">
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="name"
                                                placeholder="Your Name"
                                                value={name}
                                                onChange={(e) => setName(e.target.value)}
                                            />
                                            <label htmlFor="name">Your Name</label>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-floating">
                                            <input
                                                type="email"
                                                className="form-control"
                                                id="email"
                                                placeholder="Your Email"
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                            />
                                            <label htmlFor="email">Your Email</label>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-floating" style={{ backgroundColor: 'white' }}>
                                            <StyledDatePicker
                                                selected={selectedDate}
                                                onChange={handleDateChange}
                                                dateFormat="P"
                                                id="datetime"
                                                placeholderText="Select Date"
                                                className="form-control table-date"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-floating" style={{ backgroundColor: 'white' }}>
                                            <StyledDatePicker
                                                selected={selectedTime}
                                                onChange={handleTimeChange}
                                                showTimeSelect
                                                showTimeSelectOnly
                                                timeIntervals={15}
                                                timeCaption="Time"
                                                dateFormat="h:mm aa"
                                                className="form-control table-date"
                                                id="time"
                                                placeholderText="Select Time"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-floating">
                                            <input
                                                type="number"
                                                className="form-control"
                                                id="select1"
                                                placeholder="No Of People"
                                                value={noOfPeople}
                                                onChange={(e) => setNoOfPeople(e.target.value)}
                                            />
                                            <label htmlFor="select1">No Of People</label>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-floating">
                                            <textarea
                                                className="form-control"
                                                placeholder="Special Request"
                                                id="message"
                                                style={{ height: '100px' }}
                                                value={specialRequest}
                                                onChange={(e) => setSpecialRequest(e.target.value)}
                                            />
                                            <label htmlFor="message">Special Request</label>
                                        </div>
                                    </div>
                                    <div className="col-12 d-flex justify-content-between flex-column flex-md-row">
                                        <button
                                            type="button"
                                            className="btn btn-primary mb-3 mb-md-0 me-md-3 w-100 w-md-45 py-3"
                                            onClick={() => handleBooking('whatsapp')}
                                        >
                                            Book via WhatsApp
                                        </button>
                                        <button
                                            type="button"
                                            className="btn btn-primary w-100 w-md-45 py-3"
                                            onClick={() => handleBooking('email')}
                                        >
                                            Book via Email
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="videoModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content rounded-0">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Youtube Video</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            16:9 aspect ratio
                            <div className="ratio ratio-16x9">
                                <iframe className="embed-responsive-item" src="" id="video" allowFullScreen allowscriptaccess="always"
                                        allow="autoplay"></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*Reservation End*/}


            {/*Event Start*/}
            <EventHome/>
            {/*Event End*/}


            {/*Gallery Start*/}
            <div className="gallery-container container-xxl pt-5 pb-3" >
                <div id="gallery"  className="container">
                    <div className="text-center wow zoom-out-down" data-wow-delay="0.1s" data-aos="zoom-out-down">
                        <h5 className="section-title ff-secondary text-center text-primary fw-normal">Our Gallery</h5>
                        <h1 className="mb-5" data-aos="fade-up" data-aos-delay="200">Gallery</h1>
                    </div>

                    <div className="gallery-grid">
                        <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}>
                            <Masonry gutter="10px">
                                {imagesToShow.map((img, i) => (
                                    <div key={i} className="masonry-item" data-aos="fade-up" data-aos-delay={`${i * 100}`}>
                                        <img
                                            src={img.image}
                                            className="gallery-image"
                                            alt={`Gallery Image ${i + 1}`}
                                            onClick={() => openModal(img.image)}
                                        />
                                    </div>
                                ))}
                            </Masonry>
                        </ResponsiveMasonry>

                        <Modal
                            isOpen={isOpen}
                            onRequestClose={closeModal}
                            className="modal"
                            overlayClassName="modal-overlay"
                        >
                            <button onClick={closeModal} className="close-btn">×</button>
                            <img src={currentImage} alt="Current" className="modal-image" />
                        </Modal>

                        <div className="text-center mt-4">
                            <button
                                className="btn btn-primary"
                                onClick={toggleShowMore}
                            >
                                {showMore ? 'See Less' : 'See More'}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {/*Gallery End*/}

            {/*Testimonial Start*/}
            <div id="comment" className="container-xxl py-5 wow fadeInUp" data-wow-delay="0.1s">
                <div className="container">
                    <div className="text-center">
                        <h5 className="section-title ff-secondary text-center text-primary fw-normal">Testimonial</h5>
                        <h1 className="mb-5">Our Clients Say!!!</h1>
                    </div>
                    <OwlCarousel className="owl-theme" {...options}>
                        {testimonials.map((testimonial, index) => (
                            <div key={index} className="testimonial-item bg-transparent border rounded p-4">
                                <i className="fa fa-quote-left fa-2x text-primary mb-3"></i>
                                <p>{testimonial.text}</p>
                                <div className="d-flex align-items-center">
                                    <img
                                        className="img-fluid flex-shrink-0 rounded-circle"
                                        src={testimonial.img}
                                        alt={testimonial.name}
                                        style={{ width: '50px', height: '50px' }}
                                    />
                                    <div className="ps-3">
                                        <h5 className="mb-1">{testimonial.name}</h5>
                                        <small>{testimonial.profession}</small>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </OwlCarousel>
                </div>
            </div>
           {/* Testimonial End*/}


            <div id="contact" className="container-xxl py-5" >
                <div className="container">
                    <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
                        <h5 className="section-title ff-secondary text-center text-primary fw-normal">Contact Us</h5>
                        <h1 className="mb-5">Contact For Any Query</h1>
                    </div>
                    <div className="row g-4">
                        <div className="col-12">
                            <div className="row gy-4">
                                <div className="col-md-4">
                                    <h5 className="section-title ff-secondary fw-normal text-start text-primary">Booking</h5>
                                    <p><i className="fa fa-globe text-primary me-2"></i>huginnhikkaduwa.com</p>
                                </div>

                                <div className="col-md-4">
                                    <h5 className="section-title ff-secondary fw-normal text-start text-primary">General</h5>
                                    <p><i className="fa fa-envelope-open text-primary me-2"></i>huginnhikkaduwa@gmail.com</p>
                                </div>
                                <div className="col-md-4">
                                    <h5 className="section-title ff-secondary fw-normal text-start text-primary">Phone</h5>
                                    <p><i className="fa fa-phone text-primary me-2"></i>+94 77 201 6522</p>
                                </div>

                            </div>
                        </div>
                        <div className="col-md-6 wow fadeIn" data-wow-delay="0.1s">
                            <iframe className="position-relative rounded w-100 h-100"
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3964.80501392236!2d80.0024653!3d6.419094599999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ae2452b523cf5b1%3A0xb878b6ed868c6477!2sGalle%20Rd!5e0!3m2!1sen!2slk!4v1724743811977!5m2!1sen!2slk"
                                    frameBorder="0" style={{minHeight: '350px', border:0}} allowFullScreen="" aria-hidden="false"
                                    tabIndex="0"></iframe>
                        </div>
                        <div className="col-md-6">
                            <div className="wow fadeInUp" data-wow-delay="0.2s">
                                <form onSubmit={(e) => e.preventDefault()}>
                                    <div className="row g-3">
                                        <div className="col-md-6">
                                            <div className="form-floating">
                                                <input type="text" className="form-control" id="name" placeholder="Your Name"
                                                       value={name}
                                                       onChange={(e) => setName(e.target.value)}/>
                                                <label htmlFor="name">Your Name</label>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-floating">
                                                <input type="email" className="form-control" id="email" placeholder="Your Email"
                                                       value={email}
                                                       onChange={(e) => setEmail(e.target.value)}/>
                                                <label htmlFor="email">Your Email</label>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-floating">
                                                <input type="text" className="form-control" id="subject" placeholder="Subject"
                                                       value={subject}
                                                       onChange={(e) => setSubject(e.target.value)}/>
                                                <label htmlFor="subject">Subject</label>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-floating">
                                                <textarea className="form-control" placeholder="Leave a message here" id="message" style={{height: '150px'}}
                                                          value={message}
                                                          onChange={(e) => setMessage(e.target.value)}></textarea>
                                                <label htmlFor="message">Message</label>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <button className="btn btn-primary w-100 py-3" type="submit"
                                                    onClick={() => handleBooking('email')}>Send Message</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
import {Navbar} from "./component/Navbar";
import {Footer} from "./component/Footer";
import AOS from 'aos';
import 'aos/dist/aos.css';
import React from "react"; // Import AOS styles
import { BrowserRouter, Route, Routes } from "react-router-dom";
import {Home} from "./page/Home";
import {About} from "./page/About";
import {Services} from "./page/Services";
import {Menu} from "./page/Menu";
import {BookTable} from "./page/BookTable";
import {Event} from "./page/Event";
import {CustomerComment} from "./component/CustomerComment";
import {Contact} from "./page/Contact";
import {Gallery} from "./page/Gallery";


AOS.init({
  duration: 1000, // Duration of animations (in milliseconds)
  easing: 'ease-in-out', // Easing function
  once: true, // Whether animation should happen only once
});

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Navbar/>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About/>}/>
          <Route path="/service" element={<Services/>}/>
          <Route path="/menu" element={<Menu/>}/>
          <Route path="/book" element={<BookTable/>}/>
          <Route path="/event" element={<Event/>}/>
          <Route path="/comment" element={<CustomerComment/>}/>
          <Route path="/contact" element={<Contact/>}/>
          <Route path="/gallery" element={<Gallery/>}/>
        </Routes>
        <Footer/>
      </BrowserRouter>
    </div>
  );
}

export default App;

import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

export const Navbar = () => {
    const location = useLocation();
    const [activeItem, setActiveItem] = useState('');
    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const [isNavbarOpen, setNavbarOpen] = useState(false);

    useEffect(() => {
        const currentPath = location.pathname === '/' ? 'Home' : location.pathname.substring(1);
        setActiveItem(currentPath.charAt(0).toUpperCase() + currentPath.slice(1));
    }, [location.pathname]);

    const handleItemClick = (item) => {
        setActiveItem(item);
        setDropdownOpen(false);
        setNavbarOpen(false);

        // Scroll to the top of the page
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const toggleDropdown = () => {
        setDropdownOpen(!isDropdownOpen);
    };

    const toggleNavbar = () => {
        setNavbarOpen(!isNavbarOpen);
    };

    return (
        <div className="container-xxl position-relative p-0">
            <nav className="navbar navbar-expand-lg navbar-dark bg-dark px-4 px-lg-5 py-3 py-lg-0">
                <Link to="/" className="navbar-brand p-0" onClick={() => handleItemClick('Home')}>
                    <h1 className="text-primary m-0">
                        <img src='/img/huginLogo-removebg-preview.png' alt="Logo"/>
                    </h1>
                </Link>
                <button
                    className="navbar-toggler"
                    type="button"
                    onClick={toggleNavbar}
                >
                    <span className="fa fa-bars"></span>
                </button>
                <div className={`navbar-collapse ${isNavbarOpen ? 'show' : 'collapse'}`} id="navbarCollapse">
                    <div className="navbar-nav ms-auto py-0 pe-4">
                        {['Home', 'About', 'Service', 'Menu', 'Gallery', 'Contact'].map((item) => (
                            <Link
                                to={`/${item === 'Home' ? '' : item.toLowerCase()}`}
                                key={item}
                                className={`nav-item nav-link ${activeItem === item ? 'active' : ''}`}
                                onClick={() => handleItemClick(item)}
                            >
                                {item}
                            </Link>
                        ))}
                        <div className="nav-item dropdown">
                            <button
                                className={`nav-link dropdown-toggle ${isDropdownOpen ? 'show' : ''}`}
                                onClick={toggleDropdown}
                            >
                                Pages
                            </button>
                            <div className={`dropdown-menu m-0 ${isDropdownOpen ? 'show' : ''}`}>
                                <Link to="/book" className="dropdown-item" onClick={() => handleItemClick('Booking')}>Booking</Link>
                                <Link to="/event" className="dropdown-item" onClick={() => handleItemClick('Our Events')}>Our Events</Link>
                                <Link to="/comment" className="dropdown-item" onClick={() => handleItemClick('Client comments')}>Client comments</Link>
                            </div>
                        </div>
                    </div>
                    <Link to="book" className="btn btn-primary py-2 px-4">Book A Table</Link>
                </div>
            </nav>
        </div>
    );
};

import React from "react";

export const Services = () => {

    return(

        <>
            <div className="container-xxl py-5 bg-dark hero-header mb-5">
                <div className="container text-center my-5 pt-5 pb-4">
                    <h1 className="display-3 text-white mb-3 animated slideInDown">Service</h1>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb justify-content-center text-uppercase">
                            <li className="breadcrumb-item"><a href="#">Home</a></li>
                            <li className="breadcrumb-item text-white active" aria-current="page">Service</li>
                        </ol>
                    </nav>
                </div>
            </div>

            <div id="service" className="container-xxl py-5">
                <div className="container">
                    <div className="row g-4">
                        <div className="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.1s">
                            <div className="service-item rounded pt-5 d-flex flex-column h-100">
                                <div className="icon-wrapper">
                                    <i className="fa fa-user-tie text-black-90"></i>
                                </div>
                                <div className="p-4 text-center flex-grow-1">
                                    <h5>Master Chefs</h5>
                                    <p>Our expert chefs craft exquisite dishes with passion, blending flavors to create an unforgettable dining experience.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.3s">
                            <div className="service-item rounded pt-5 d-flex flex-column h-100">
                                <div className="icon-wrapper">
                                    <i className="fa fa-utensils text-black-90"></i>
                                </div>
                                <div className="p-4 text-center flex-grow-1">
                                    <h5>Quality Food</h5>
                                    <p>Our commitment to quality ensures every dish is made with the freshest ingredients, delivering flavors that delight your senses.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.5s">
                            <div className="service-item rounded pt-5 d-flex flex-column h-100">
                                <div className="icon-wrapper">
                                    <i className="fa fa-cart-plus text-black-90"></i>
                                </div>
                                <div className="p-4 text-center flex-grow-1">
                                    <h5>Online Order</h5>
                                    <p>Conveniently place your order online and enjoy a seamless dining experience. Fresh ingredients, quick delivery, and delicious meals are just a click away.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.7s">
                            <div className="service-item rounded pt-5 d-flex flex-column h-100">
                                <div className="icon-wrapper">
                                    <i className="fa fa-headset text-black-90"></i>
                                </div>
                                <div className="p-4 text-center flex-grow-1">
                                    <h5>24/7 Service</h5>
                                    <p>We are here for you around the clock, providing exceptional service whenever you need it. Your satisfaction is our priority, day or night.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
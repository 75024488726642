import React from 'react';
import OwlCarousel from 'react-owl-carousel';
export const CustomerComment = () => {
    const testimonials = [
        {
            text: "Dolor et eos labore, stet justo sed est sed. Diam sed sed dolor stet amet eirmod eos labore diam",
            img: "/img/testimonial-1.jpg",
            name: "Client Name 1",
            profession: "Profession 1",
        },
        {
            text: "Dolor et eos labore, stet justo sed est sed. Diam sed sed dolor stet amet eirmod eos labore diam",
            img: "/img/testimonial-2.jpg",
            name: "Client Name 2",
            profession: "Profession 2",
        },
        {
            text: "Dolor et eos labore, stet justo sed est sed. Diam sed sed dolor stet amet eirmod eos labore diam",
            img: "/img/testimonial-3.jpg",
            name: "Client Name 3",
            profession: "Profession 3",
        },
        {
            text: "Dolor et eos labore, stet justo sed est sed. Diam sed sed dolor stet amet eirmod eos labore diam",
            img: "/img/testimonial-4.jpg",
            name: "Client Name 4",
            profession: "Profession 4",
        },
    ];

    const options = {
        loop: true,
        margin: 10,
        nav: false,
        dots: false,
        autoplay: true,
        autoplayTimeout: 3000, // 3 seconds between each slide
        autoplayHoverPause: true, // Pause on hover
        responsive: {
            0: {
                items: 1,
            },
            600: {
                items: 2,
            },
            1000: {
                items: 3,
            },
        },
    };

    return (
        <>

            <div className="container-xxl py-5 bg-dark hero-header mb-5">
                <div className="container text-center my-5 pt-5 pb-4">
                    <h1 className="display-3 text-white mb-3 animated slideInDown">Comments</h1>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb justify-content-center text-uppercase">
                            <li className="breadcrumb-item"><a href="#">Home</a></li>
                            <li className="breadcrumb-item text-white active" aria-current="page">Comments</li>
                        </ol>
                    </nav>
                </div>
            </div>

        <div id="comment" className="container-xxl py-5 wow fadeInUp" data-wow-delay="0.1s">
            <div className="container">
                <div className="text-center">
                    <h5 className="section-title ff-secondary text-center text-primary fw-normal">Testimonial</h5>
                    <h1 className="mb-5">Our Clients Say!!!</h1>
                </div>
                <OwlCarousel className="owl-theme" {...options}>
                    {testimonials.map((testimonial, index) => (
                        <div key={index} className="testimonial-item bg-transparent border rounded p-4">
                            <i className="fa fa-quote-left fa-2x text-primary mb-3"></i>
                            <p>{testimonial.text}</p>
                            <div className="d-flex align-items-center">
                                <img
                                    className="img-fluid flex-shrink-0 rounded-circle"
                                    src={testimonial.img}
                                    alt={testimonial.name}
                                    style={{ width: '50px', height: '50px' }}
                                />
                                <div className="ps-3">
                                    <h5 className="mb-1">{testimonial.name}</h5>
                                    <small>{testimonial.profession}</small>
                                </div>
                            </div>
                        </div>
                    ))}
                </OwlCarousel>
            </div>
        </div>

        </>
    );
};



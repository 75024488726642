import React, { useState, useEffect, useRef } from 'react';
import 'aos/dist/aos.css'; // Import AOS styles
import AOS from 'aos';
import { Modal, Button } from 'react-bootstrap'; // Import Bootstrap components

// Initialize AOS
AOS.init();

const isOverflowed = (element) => {
    return element.scrollHeight > element.clientHeight;
};

const EventCard = ({ image, name, date, description, link, aos }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [needsReadMore, setNeedsReadMore] = useState(false);
    const [showModal, setShowModal] = useState(false); // State for showing modal
    const descriptionRef = useRef(null);

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    const checkOverflow = () => {
        if (descriptionRef.current) {
            setNeedsReadMore(isOverflowed(descriptionRef.current));
        }
    };

    const handleShowModal = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    useEffect(() => {
        checkOverflow();
        window.addEventListener('resize', checkOverflow);
        return () => window.removeEventListener('resize', checkOverflow);
    }, []);

    return (
        <>
            <div
                style={{
                    border: '1px solid #ddd',
                    borderRadius: '10px',
                    overflow: 'hidden',
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                    marginBottom: '20px',
                    display: 'flex',
                    flexDirection: 'column',
                    height: '400px', // Fixed height for all items
                }}
                data-aos={aos}
            >
                <div
                    style={{
                        height: '200px',
                        backgroundImage: `url(${image})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                    }}
                ></div>
                <div style={{ padding: '15px', flex: '1' }}>
                    <h5>{name}</h5>
                    <small>{date}</small>
                    <div
                        ref={descriptionRef}
                        style={{
                            maxHeight: isExpanded ? 'none' : '60px', // Fixed height when not expanded
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            marginBottom: '10px',
                            transition: 'max-height 0.3s ease',
                        }}
                    >
                        {description}
                    </div>
                    {!isExpanded && needsReadMore && (
                        <a
                            onClick={toggleExpand}
                            style={{ cursor: 'pointer', color: '#FEA116' }}
                        >
                            Read More
                        </a>
                    )}
                    {isExpanded && (
                        <a
                            onClick={toggleExpand}
                            style={{ cursor: 'pointer', color: '#FEA116' }}
                        >
                            Show Less
                        </a>
                    )}
                    <div style={{ marginTop: '10px', textAlign: 'center' }}>
                        <Button variant="primary" onClick={handleShowModal} style={{ maxWidth: '200px', width: '100%' }}>
                            View More
                        </Button>
                    </div>
                </div>
            </div>

            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <img src={image} alt={name} style={{ width: '100%', marginBottom: '15px' }} />
                    <h5>Date: {date}</h5>
                    <p>{description}</p>
                    <p>For more details, visit <a href={link} target="_blank" rel="noopener noreferrer">here</a>.</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export const Event = () => {
    return (
        <>
            <div className="container-xxl py-5 bg-dark hero-header mb-5">
                <div className="container text-center my-5 pt-5 pb-4">
                    <h1 className="display-3 text-white mb-3 animated slideInDown">Event</h1>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb justify-content-center text-uppercase">
                            <li className="breadcrumb-item"><a href="#">Home</a></li>
                            <li className="breadcrumb-item"><a href="#">Pages</a></li>
                            <li className="breadcrumb-item text-white active" aria-current="page">Event</li>
                        </ol>
                    </nav>
                </div>
            </div>

            <div id="event" className="container-xxl pt-5 pb-3">
                <div className="container">
                    <div className="text-center wow fadeInUp" data-wow-delay="0.1s" data-aos="fade-up">
                        <h5 className="section-title ff-secondary text-center text-primary fw-normal">Upcoming Events</h5>
                        <h1 className="mb-5">Don’t Miss Our Events</h1>
                    </div>
                    <div className="row g-4">
                        <div className="col-lg-3 col-md-6 wow fade-down-right" data-wow-delay="0.1s" data-aos="fade-down-right">
                            <EventCard
                                image="img/event1.jpg"
                                name="Gourmet Night"
                                date="September 10, 2024"
                                description="Join us for a night of gourmet delights with a special menu crafted by our master chefs."
                                link="#"
                                aos="fade-up"
                            />
                        </div>
                        <div className="col-lg-3 col-md-6 wow fade-down-right" data-wow-delay="0.1s" data-aos="fade-down-right">
                            <EventCard
                                image="img/event2.jpg"
                                name="Wine Tasting"
                                date="September 17, 2024"
                                description="Celebrate the holidays with a grand feast, featuring seasonal favorites and special treats."
                                link="#"
                                aos="fade-up"
                            />
                        </div>
                        <div className="col-lg-3 col-md-6 wow fade-down-right" data-wow-delay="0.1s" data-aos="fade-down-right">
                            <EventCard
                                image="img/event3.jpg"
                                name="Live Jazz Dinner"
                                date="September 24, 2024"
                                description="Celebrate the holidays with a grand feast, featuring seasonal favorites and special treats."
                                link="#"
                                aos="fade-up"
                            />
                        </div>
                        <div className="col-lg-3 col-md-6 wow fade-down-right" data-wow-delay="0.1s" data-aos="fade-down-right">
                            <EventCard
                                image="img/event1.jpg"
                                name="Holiday Feast"
                                date="December 25, 2024"
                                description="Celebrate the holidays with a grand feast, featuring seasonal favorites and special treats."
                                link="#"
                                aos="fade-up"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

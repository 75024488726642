import React, { useState } from 'react';
import 'aos/dist/aos.css'; // Import AOS styles
import AOS from 'aos';

// Initialize AOS
AOS.init();

export const About = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const toggleModal = () => {
        setIsModalOpen(!isModalOpen);
    };

    return (
        <>
            <div className="container-xxl py-5 bg-dark hero-header mb-5">
                <div className="container text-center my-5 pt-5 pb-4">
                    <h1 className="display-3 text-white mb-3 animated slideInDown">About Us</h1>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb justify-content-center text-uppercase">
                            <li className="breadcrumb-item"><a href="#">Home</a></li>
                            <li className="breadcrumb-item text-white active" aria-current="page">About Us</li>
                        </ol>
                    </nav>
                </div>
            </div>

            <div className="container-xxl py-5" id="about">
                <div className="container">
                    <div className="row g-5 align-items-center">
                        <div className="col-lg-6">
                            <div className="row g-3">
                                <div className="col-6 text-start" data-aos="zoom-in" data-aos-delay="100">
                                    <img className="img-fluid rounded w-100" src="img/about-1.jpg" alt="About Image 1" />
                                </div>
                                <div className="col-6 text-start" data-aos="zoom-in" data-aos-delay="300">
                                    <img className="img-fluid rounded w-75" src="img/about-2.jpg" alt="About Image 2" style={{ marginTop: '25%' }} />
                                </div>
                                <div className="col-6 text-end" data-aos="zoom-in" data-aos-delay="500">
                                    <img className="img-fluid rounded w-75" src="img/about-3.jpg" alt="About Image 3" />
                                </div>
                                <div className="col-6 text-end" data-aos="zoom-in" data-aos-delay="700">
                                    <img className="img-fluid rounded w-100" src="img/about-4.jpg" alt="About Image 4" />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <h5 className="section-title ff-secondary text-center text-primary fw-normal" data-aos="fade-up">About Us</h5>
                            <h1 className="mb-4" data-aos="fade-up" data-aos-delay="200">
                                Welcome to <i className="fa fa-utensils text-primary me-2"></i>Hug Inn
                            </h1>
                            <p className="mb-4" data-aos="fade-up" data-aos-delay="300">
                                At Hug Inn, we blend tradition and innovation to bring you a dining experience like no other. Every dish is prepared with the finest ingredients, ensuring a symphony of flavors in every bite.
                            </p>
                            <p className="mb-4" data-aos="fade-up" data-aos-delay="400">
                                Whether you’re joining us for a casual meal or a special occasion, our welcoming atmosphere and exceptional service will make your visit memorable. Discover the passion behind our cuisine, where every meal tells a story.
                            </p>
                            <div className="row g-4 mb-4">
                                <div className="col-sm-6" data-aos="fade-up" data-aos-delay="500">
                                    <div className="d-flex align-items-center border-start border-5 border-primary px-3">
                                        <h1 className="flex-shrink-0 display-5 text-primary mb-0" data-toggle="counter-up">15</h1>
                                        <div className="ps-4">
                                            <p className="mb-0">Years of</p>
                                            <h6 className="text-uppercase mb-0">Experience</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6" data-aos="fade-up" data-aos-delay="600">
                                    <div className="d-flex align-items-center border-start border-5 border-primary px-3">
                                        <h1 className="flex-shrink-0 display-5 text-primary mb-0" data-toggle="counter-up">50</h1>
                                        <div className="ps-4">
                                            <p className="mb-0">Popular</p>
                                            <h6 className="text-uppercase mb-0">Master Chefs</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <button className="btn btn-primary py-3 px-5 mt-2" onClick={toggleModal} data-aos="fade-up" data-aos-delay="700">
                                Read More
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {/* Modal */}
            {isModalOpen && (
                <div className="modal show" style={{ display: 'block' }} tabIndex="-1">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">More About Us</h5>
                                <button type="button" className="btn-close" onClick={toggleModal}></button>
                            </div>
                            <div className="modal-body">
                                <p>
                                    At Hug Inn, our journey began over 15 years ago with a passion for culinary excellence. Our team of 50 master chefs brings diverse flavors and innovative techniques to the table, creating dishes that are as memorable as they are delicious. Whether you are a frequent guest or a first-time visitor, we are committed to making your dining experience exceptional.
                                </p>
                                <p>
                                    Our restaurant is more than just a place to eat—it's a destination where you can relax, enjoy good company, and savor a meal prepared with love and dedication. Thank you for choosing Hug Inn. We look forward to serving you!
                                </p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" onClick={toggleModal}>
                                    Close
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {/* Backdrop */}
            {isModalOpen && <div className="modal-backdrop fade show"></div>}
        </>
    );
};

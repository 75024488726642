import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import styled from 'styled-components';
import emailjs from 'emailjs-com';

export const BookTable = () => {
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedTime, setSelectedTime] = useState(null);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [noOfPeople, setNoOfPeople] = useState(null);
    const [specialRequest, setSpecialRequest] = useState('');

    const handleDateChange = (date) => setSelectedDate(date);
    const handleTimeChange = (time) => setSelectedTime(time);

    const handleBooking = (method) => {
        const bookingDetails = `Name: ${name}\nDate: ${selectedDate?.toLocaleDateString()}\nTime: ${selectedTime?.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}\nPeople: ${noOfPeople}\nSpecial Request: ${specialRequest}`;
        const receiverPhoneNumber = '+94774163553'; // Ensure this is correct

        if (method === 'whatsapp') {
            const whatsappUrl = `https://wa.me/${receiverPhoneNumber}?text=${encodeURIComponent(bookingDetails)}`;
            window.open(whatsappUrl, '_blank');
        } else if (method === 'email') {
            const emailData = {
                name,
                email,
                date: selectedDate?.toLocaleDateString(),
                time: selectedTime?.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
                noOfPeople,
                specialRequest,
            };

            emailjs.send(
                'service_p8unrnn',        // Replace with your EmailJS service ID
                'template_21fewum',       // Replace with your EmailJS template ID
                emailData,
                'R7u8CJEZzlSXzwXH7'       // Replace with your EmailJS user ID
            )
                .then((response) => {
                    console.log('SUCCESS!', response.status, response.text);
                    alert('Email sent successfully!');
                })
                .catch((err) => {
                    console.error('FAILED...', err);
                    alert('Failed to send email. Please try again later.');
                });
        }
    };

    const StyledDatePicker = styled(DatePicker)`
      width: 100%;
      height: 60px;
      border: none !important;
      box-shadow: none !important;
      background-color: white;
    `;

    return (
        <>
            <div className="container-xxl py-5 bg-dark hero-header mb-5">
                <div className="container text-center my-5 pt-5 pb-4">
                    <h1 className="display-3 text-white mb-3 animated slideInDown">Book Table</h1>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb justify-content-center text-uppercase">
                            <li className="breadcrumb-item"><a href="#">Home</a></li>
                            <li className="breadcrumb-item"><a href="#">Pages</a></li>
                            <li className="breadcrumb-item text-white active" aria-current="page">Book Table</li>
                        </ol>
                    </nav>
                </div>
            </div>

            <div id="book" className="container-xxl py-5 px-0">
                <div className="row g-0">
                    <div className="col-md-6">
                        <div className="video">
                            <button type="button" className="btn-play" data-bs-toggle="modal" data-src="https://www.youtube.com/embed/0GdQY5ese0s?si=0agflFjzhdhI9gbT" data-bs-target="#videoModal">
                                <span></span>
                            </button>
                        </div>
                    </div>
                    <div className="col-md-6 bg-dark d-flex align-items-center">
                        <div className="p-5 wow fadeInUp" data-wow-delay="0.2s">
                            <h5 className="section-title ff-secondary text-start text-primary fw-normal">Reservation</h5>
                            <h1 className="text-white mb-4">Book A Table Online</h1>
                            <form onSubmit={(e) => e.preventDefault()}>
                                <div className="row g-3">
                                    <div className="col-md-6">
                                        <div className="form-floating">
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="name"
                                                placeholder="Your Name"
                                                value={name}
                                                onChange={(e) => setName(e.target.value)}
                                            />
                                            <label htmlFor="name">Your Name</label>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-floating">
                                            <input
                                                type="email"
                                                className="form-control"
                                                id="email"
                                                placeholder="Your Email"
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                            />
                                            <label htmlFor="email">Your Email</label>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-floating" style={{ backgroundColor: 'white' }}>
                                            <StyledDatePicker
                                                selected={selectedDate}
                                                onChange={handleDateChange}
                                                dateFormat="P"
                                                id="datetime"
                                                placeholderText="Select Date"
                                                className="form-control table-date"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-floating" style={{ backgroundColor: 'white' }}>
                                            <StyledDatePicker
                                                selected={selectedTime}
                                                onChange={handleTimeChange}
                                                showTimeSelect
                                                showTimeSelectOnly
                                                timeIntervals={15}
                                                timeCaption="Time"
                                                dateFormat="h:mm aa"
                                                className="form-control table-date"
                                                id="time"
                                                placeholderText="Select Time"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-floating">
                                            <input
                                                type="number"
                                                className="form-control"
                                                id="select1"
                                                placeholder="No Of People"
                                                value={noOfPeople}
                                                onChange={(e) => setNoOfPeople(e.target.value)}
                                            />
                                            <label htmlFor="select1">No Of People</label>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-floating">
                                            <textarea
                                                className="form-control"
                                                placeholder="Special Request"
                                                id="message"
                                                style={{ height: '100px' }}
                                                value={specialRequest}
                                                onChange={(e) => setSpecialRequest(e.target.value)}
                                            />
                                            <label htmlFor="message">Special Request</label>
                                        </div>
                                    </div>
                                    <div className="col-12 d-flex justify-content-between flex-column flex-md-row">
                                        <button
                                            type="button"
                                            className="btn btn-primary mb-3 mb-md-0 me-md-3 w-100 w-md-45 py-3"
                                            onClick={() => handleBooking('whatsapp')}
                                        >
                                            Book via WhatsApp
                                        </button>
                                        <button
                                            type="button"
                                            className="btn btn-primary w-100 w-md-45 py-3"
                                            onClick={() => handleBooking('email')}
                                        >
                                            Book via Email
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="videoModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content rounded-0">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Youtube Video</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="ratio ratio-16x9">
                                <iframe
                                    className="embed-responsive-item"
                                    src=""
                                    id="video"
                                    allowFullScreen
                                    allowscriptaccess="always"
                                    allow="autoplay"
                                ></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

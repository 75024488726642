import React, { useState } from 'react';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import Modal from 'react-modal';
import 'aos/dist/aos.css'; // Import AOS styles
import AOS from 'aos';

// Initialize AOS
AOS.init();

// Sample images array with more than 6 images for the "See More" functionality
const allImages = [
    { image: 'img/event1.jpg' },
    { image: 'img/event3.jpg' },
    { image: 'img/event2.jpg' },
    { image: 'img/event3.jpg' },
    { image: 'img/event2.jpg' },
    { image: 'img/event1.jpg' },
    { image: 'img/event3.jpg' },
    { image: 'img/event2.jpg' },
    { image: 'img/event1.jpg' },
    { image: 'img/event2.jpg' },
    { image: 'img/event3.jpg' }
];

export const Gallery = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [currentImage, setCurrentImage] = useState('');
    const [showMore, setShowMore] = useState(false); // State to toggle between showing more or less

    const openModal = (img) => {
        setCurrentImage(img);
        setIsOpen(true);
    };

    const closeModal = () => {
        setIsOpen(false);
        setCurrentImage('');
    };

    const toggleShowMore = () => {
        setShowMore(!showMore);
    };

    // Determine the images to display based on the showMore state
    const imagesToShow = showMore ? allImages : allImages.slice(0, 6);

    return (
        <>
            <div className="container-xxl py-5 bg-dark hero-header mb-5">
                <div className="container text-center my-5 pt-5 pb-4">
                    <h1 className="display-3 text-white mb-3 animated slideInDown">Gallery</h1>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb justify-content-center text-uppercase">
                            <li className="breadcrumb-item"><a href="#">Home</a></li>
                            <li className="breadcrumb-item text-white active" aria-current="page">Gallery</li>
                        </ol>
                    </nav>
                </div>
            </div>

            <div className="gallery-container container-xxl pt-5 pb-3" >
                <div id="gallery"  className="container">
                    <div className="text-center wow zoom-out-down" data-wow-delay="0.1s" data-aos="zoom-out-down">
                        <h5 className="section-title ff-secondary text-center text-primary fw-normal">Our Gallery</h5>
                        <h1 className="mb-5" data-aos="fade-up" data-aos-delay="200">Gallery</h1>
                    </div>

                    <div className="gallery-grid">
                        <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}>
                            <Masonry gutter="10px">
                                {imagesToShow.map((img, i) => (
                                    <div key={i} className="masonry-item" data-aos="fade-up" data-aos-delay={`${i * 100}`}>
                                        <img
                                            src={img.image}
                                            className="gallery-image"
                                            alt={`Gallery Image ${i + 1}`}
                                            onClick={() => openModal(img.image)}
                                        />
                                    </div>
                                ))}
                            </Masonry>
                        </ResponsiveMasonry>

                        <Modal
                            isOpen={isOpen}
                            onRequestClose={closeModal}
                            className="modal"
                            overlayClassName="modal-overlay"
                        >
                            <button onClick={closeModal} className="close-btn">×</button>
                            <img src={currentImage} alt="Current" className="modal-image" />
                        </Modal>

                        <div className="text-center mt-4">
                            <button
                                className="btn btn-primary"
                                onClick={toggleShowMore}
                            >
                                {showMore ? 'See Less' : 'See More'}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export const Footer = () => {
  return (
    <>
      <div
        className="container-fluid bg-dark text-light footer pt-5 mt-5 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="container py-5">
          <div className="row g-5">
            <div className="col-lg-4 col-md-6">
              <h4 className="section-title ff-secondary text-start text-primary fw-normal mb-4">
                Company
              </h4>
              <a className="btn btn-link" href="/">
                Home
              </a>
              <a className="btn btn-link" href="about">
                About Us
              </a>
              <a className="btn btn-link" href="contact">
                Contact Us
              </a>
              <a className="btn btn-link" href="menu">
                Menu
              </a>
              <a className="btn btn-link" href="book">
                Book Table
              </a>
            </div>
            <div className="col-lg-4 col-md-6">
              <h4 className="section-title ff-secondary text-start text-primary fw-normal mb-4">
                Contact
              </h4>
              <p className="mb-2">
                <i className="fa fa-map-marker-alt me-3"></i>Galle road,
                Narigama, Hikkaduwa
              </p>
              <p className="mb-2">
                <i className="fa fa-phone-alt me-3"></i>+94 77 201 6522
              </p>
              <p className="mb-2">
                <i className="fa fa-envelope me-3"></i>huginnhikkaduwa@gmail.com
              </p>
              <div className="d-flex pt-2">
                <a
                  className="btn btn-outline-light btn-social"
                  href="https://www.facebook.com/share/zDQzBxnNj2J9ASau/?mibextid=qi2Omg"
                  target="_blank"
                >
                  <i className="fab fa-facebook-f"></i>
                </a>
                <a className="btn btn-outline-light btn-social" href="">
                  <i className="fab fa-youtube"></i>
                </a>
                {/* <a className="btn btn-outline-light btn-social" href=""><i className="fab fa-linkedin-in"></i></a> */}
                <a
                  className="btn btn-outline-light btn-social"
                  href="https://www.instagram.com/huginn_hikkaduwa?igsh=Ymt3ajlkeW5jYnZu"
                  target="_blank"
                >
                  <i className="fab fa-instagram"></i>
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <h4 className="section-title ff-secondary text-start text-primary fw-normal mb-4">
                Opening
              </h4>
              <h5 className="text-light fw-normal">Monday - Saturday</h5>
              <p>09AM - 09PM</p>
              <h5 className="text-light fw-normal">Sunday</h5>
              <p>10AM - 08PM</p>
            </div>
          </div>
        </div>

        <div className="container text-decoration-none">
  <div className="copyright">
    <div className="row justify-content-center">
      <div className="col-md-6 text-center">
        <p className="mb-0">
          &copy; {new Date().getFullYear()} All Rights Reserved. Developed By{" "}
          <a
            className="border-bottom text-decoration-none"
            href="https://www.zeeventures.lk/"
            target="_blank"
            rel="noopener noreferrer"
          >
            zeeventures
          </a>
        </p>
      </div>
    </div>
  </div>
</div>


      </div>
    </>
  );
};
